import { Loader } from '@react-three/drei'
import React, { Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

// theme layout
import Header from 'components/Header'
import Layout from 'components/Layout'

// pages
const HomePage = React.lazy(() => import('pages/HomePage'))
const HomeAnimePage = React.lazy(() => import('pages/HomeAnimePage'))
const HomeArtHistoryPage = React.lazy(() => import('pages/HomeArtHistoryPage'))
const HomePaintNightPage = React.lazy(() => import('pages/HomePaintNightPage'))
const HomeGiantPaintPage = React.lazy(() => import('pages/HomeGiantPaintPage'))
const HomeIntroPage = React.lazy(() => import('pages/HomeIntroPage'))
const HomeLifeDrawingPage = React.lazy(() => import('pages/HomeLifeDrawingPage'))
const HomePrivateLessonPage = React.lazy(() => import('pages/HomePrivateLessonPage'))
const HomePorfolioPage = React.lazy(() => import('pages/HomePorfolioPage'))
const HomeSelfExpressionPage = React.lazy(() => import('pages/HomeSelfExpressionPage'))
const HomeWorkshopPage = React.lazy(() => import('pages/HomeWorkshopPage'))
const HomeThreePage = React.lazy(() => import('pages/HomeThreePage'))
const AboutPage = React.lazy(() => import('pages/AboutPage'))
const ContactPage = React.lazy(() => import('pages/ContactPage'))
const BookingPage = React.lazy(() => import('pages/BookingPage'))
const BookingDetailPage = React.lazy(() => import('pages/BookingDetailPage'))
const EventInfoPage = React.lazy(() => import('pages/EventInfoPage'))
const EventFormPage = React.lazy(() => import('pages/EventFormPage'))
const EventConfirmPage = React.lazy(() => import('pages/EventConfirmPage'))

const AppRoutes = () => (
  <>
    <Suspense fallback={<div />}>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<Header />}>
            {/* main tab */}
            <Route path="/" element={<HomePage />} />
            {/* 1 */}
            <Route path="/porfolio" element={<HomePorfolioPage />} />
            {/* 2 */}
            <Route path="/intro-to-art" element={<HomeIntroPage />} />
            {/* 3 */}
            <Route path="/private-lesson" element={<HomePrivateLessonPage />} />
            {/* 4 */}
            <Route path="/workshops" element={<HomeWorkshopPage />} />
            {/* 4.1 */}
            <Route path="/anime" element={<HomeAnimePage />} />
            {/* 4.2 */}
            <Route path="/life-drawing" element={<HomeLifeDrawingPage />} />
            {/* 4.3 */}
            <Route path="/self-expression" element={<HomeSelfExpressionPage />} />
            {/* 5.1 */}
            <Route path="/art-history" element={<HomeArtHistoryPage />} />
            <Route path="/giant-painting" element={<HomeGiantPaintPage />} />
            <Route path="/paint-night" element={<HomePaintNightPage />} />
            {/* 6.1 */}
            <Route path="/industrial-design" element={<HomeThreePage />} />
            {/*  */}
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            {/*  */}
            <Route path="/book-online" element={<BookingPage />} />
            <Route path="/book-online/:guid" element={<BookingDetailPage />} />
            <Route path="/event-info" element={<EventInfoPage />} />
            <Route path="/event-info/form/:guid" element={<EventFormPage />} />
            <Route path="/event-info/form/:guid/confirm" element={<EventConfirmPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Suspense>
    <Loader />
  </>
)

export default AppRoutes
