import { all, fork } from 'redux-saga/effects'

import { aboutWatcherSaga } from 'features/about/store/about.sagas'
import { bookingWatcherSaga } from 'features/booking/store/booking.sagas'
import { contactWatcherSaga } from 'features/contact/store/contact.sagas'
import { homeWatcherSaga } from 'features/home/store/home.sagas'

export function* rootSaga() {
  // list of saga
  yield all([
    fork(homeWatcherSaga),
    fork(aboutWatcherSaga),
    fork(contactWatcherSaga),
    fork(bookingWatcherSaga),
  ])
}

export default rootSaga
