import { SagaIterator } from '@redux-saga/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { call, put, takeEvery } from 'redux-saga/effects'

import { createBooking } from 'features/booking/api'
import { bookingActions } from 'features/booking/store/booking.slice'
import { APIResponse, BookingFormInput } from 'features/booking/types'

// Worker Sagas
function* onCreateBooking(action: PayloadAction<BookingFormInput>): SagaIterator {
  try {
    const response: APIResponse = yield call(createBooking, action.payload)
    if (response?.message === 'success') {
      toast.success('Thanks, confirmation email has been sent to your email')
      yield put(bookingActions.createBookingSuccess())
    } else {
      toast.error('Oops, something goes wrong. Please contact us by email')
      yield put(bookingActions.createBookingFail('api error'))
    }
  } catch (error) {
    yield put(bookingActions.createBookingFail('api error'))
  }
}

// Watcher Saga
export function* bookingWatcherSaga(): SagaIterator {
  yield takeEvery(bookingActions.createBookingRequst.type, onCreateBooking)
}

export default bookingWatcherSaga
