// DUCKS pattern
import { createAction, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'

import { Booking, BookingFormInput } from 'features/booking/types'
import type { RootState } from 'store/store'

export interface BookingState {
  isLoading: boolean
  isConfirm: boolean
  errors?: Error[]
}

const initialState: BookingState = {
  isLoading: false,
  isConfirm: false,
  errors: [],
}

// slice
export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    createBookingRequst: (state, action: PayloadAction<BookingFormInput>) => {
      state.isLoading = true
      state.isConfirm = false
    },
    createBookingSuccess: state => {
      state.isLoading = false
      state.isConfirm = true
    },
    createBookingFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.isConfirm = false
      state.errors = [new Error(action.payload)]
    },
  },
})

// Actions
export const bookingActions = {
  // create booking
  createBookingRequst: bookingSlice.actions.createBookingRequst,
  createBookingSuccess: bookingSlice.actions.createBookingSuccess,
  createBookingFail: bookingSlice.actions.createBookingFail,
}

// Selectors
export const selectIsLoading = (state: RootState) => state.booking.isLoading
export const selectIsConfirm = (state: RootState) => state.booking.isConfirm

// Reducer
export default bookingSlice.reducer
