/* eslint-disable @typescript-eslint/no-explicit-any */
import { Env } from 'config/Env'
import makeApi from 'libs/core/configureAxios'

import { Booking } from '../types'

const api = makeApi(`${Env.API_BASE_URL}`)
const BOOKING_BASE_URL = `/api/users/booking`

export const createBooking = (booking: Booking): Promise<object> =>
  api.post(BOOKING_BASE_URL, booking)
